import React from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { Button, Col, Row } from 'reactstrap';
import { AddButton, CrudList, PageTitle } from '@westernmilling/react_component_library';

import Columns from 'helpers/columns/OttoUpdates';
import ItemForm from 'components/Forms/OttoUpdate';
import ViewForm from 'components/Forms/OttoUpdateView';

export default withOktaAuth(class OttoUpdateListContainer extends CrudList {
  getColumns = () => Columns(this);

  getFormTag = () => ItemForm;

  getListApi = () => 'otto_updates';

  getPageTitle = () => 'Otto Change Log';

  getAddText = () => 'Create Change Log Entry';

  getAddGroups = () => ['otto_admin'];

  getEditGroups = () => ['otto_admin'];

  getStorageName = () => 'otto_inf_otto_updates_list';

  canDownloadCsv = () => true;

  auditLogModelType = () => 'OttoUpdate';

  getWidthCenter = () => 12;

  getWidthLeft = () => 0;

  getWidthRight = () => 0;

  openViewLogModal = (id) => this.setState({ viewLogModalOpen: true, viewItem: id });

  closeViewLogModal = () => this.setState({ viewLogModalOpen: false, viewItem: null });

  getTableClassSettings = () => 'infoTable';

  serviceDeskBase = () => (
    'https://westernmilling.atlassian.net/helpcenter/ottosupport/portal/30/'
  );

  ottoSupportTicketFormUrl = () => (
    `${this.serviceDeskBase()}group/46/create/10319`
  );

  ottoHelpFormUrl = () => (
    `${this.serviceDeskBase()}article/3782541625`
  );

  supportButtons = () => (
    <>
      <a href={this.ottoSupportTicketFormUrl()} target="_blank" rel="noreferrer">
        <AddButton onClick={() => {}} addText="Open a Support Ticket" />
      </a>
      &nbsp;&nbsp;&nbsp;
      <a href={this.ottoHelpFormUrl()} target="_blank" rel="noreferrer">
        <AddButton onClick={() => {}} addText="Help Center" />
      </a>
      &nbsp;&nbsp;&nbsp;
      {this.isAddAdmin() ? <AddButton onClick={this.handleOpenModal} addText={this.getAddText()} /> : ''}
    </>
  );

  smallTitle = () => (
    <Row>
      <Col md="7" className="text-start">
        <PageTitle title={this.getPageTitle()} subtitle={this.getPageSubtitle()} />
      </Col>
      <Col md="5" className=" text-end">
        {this.supportButtons()}
      </Col>
    </Row>
  );
});
