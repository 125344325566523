import React from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { Col, Row } from 'reactstrap';
import { CrudForm } from '@westernmilling/react_component_library';

export default withOktaAuth(class OttoUpdate extends CrudForm {
  getModalClassName = () => 'wm__modal-l';

  showButtonsAndErrors = () => false;

  modalBodyRows = () => (
    <Row>
      <Col md={2} />
      <Col md={8}>
        <div className="align-self-center">
          <h2 id="wm_page_title">{this.getItem().title}</h2>
          <div className="text-muted">{this.getItem().subtitle}</div>
          <div className="text-muted">
            Release Date:
            &nbsp;
            {this.getItem().release_date}
          </div>
        </div>
        <hr />
        {/* eslint-disable react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: this.getItem().html_body }} />
        {/* eslint-enable react/no-danger */}
      </Col>
    </Row>
  );

  auditLogs = () => '';
});
