import React from 'react';
import { ColumnFields, HelperFunctions } from '@westernmilling/react_component_library';

const baseLink = `${process.env.REACT_APP_BASE_URL}/info${process.env.REACT_APP_URL_ENV}`;
const getLink = (value, label) => (<a href={`${baseLink}/otto_updates/view/${value}`}>{label}</a>);

const Columns = (context) => [
  {
    Header: 'Release Date',
    accessor: 'release_date',
    sortMethod: HelperFunctions.dateSort,
    className: 'text-center',
    width: 200,
  },
  {
    Header: 'Change',
    accessor: 'title',
    Cell: (row) => {
      const label = <h5>{row.original.title}</h5>;
      return (
        <div>
          {getLink(row.original.uuid, label)}
          <br />
          <span className="text-muted">
            {row.original.subtitle}
          </span>
        </div>
      );
    },
    className: 'text-start',
  },
  {
    Header: 'Visible',
    accessor: 'is_visible_text',
    className: 'text-center',
    width: 150,
    show: context.isEditAdmin(),
  },
  ColumnFields.auditLogLink(context, 100, context.isEditAdmin()),
  ColumnFields.editColumn(context),
];

export default Columns;
