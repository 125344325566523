import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, withRouter } from 'react-router-dom';
import { HelperFunctions, IndexJs } from '@westernmilling/react_component_library';

import App from 'App';

HelperFunctions.suppressConsoleMessages();

const container = document.getElementById('root');
const root = createRoot(container);
const AppWithRouterAccess = withRouter(App);

root.render(
  <IndexJs app={<BrowserRouter basename={process.env.REACT_APP_BASENAME}><AppWithRouterAccess /></BrowserRouter>} />,
);
