import React from 'react';
import { withOktaAuth } from '@okta/okta-react';
import {
  Col, FormGroup, Input, Row,
} from 'reactstrap';
import { CrudForm } from '@westernmilling/react_component_library';

export default withOktaAuth(class OttoUpdate extends CrudForm {
  constructor(props) {
    super(props);
    this.state.item.html_body = this.isNew() ? this.defaultValue() : this.state.item.html_body;
  }

  formType = () => 'Change Log Entry';

  getCrudType = () => 'otto_updates';

  getModalClassName = () => 'wm__modal-l';

  handleHtmlBodyChange = (value) => {
    const newData = { ...this.state.item };
    newData.html_body = value;
    this.setState({ item: newData });
  };

  getLabelColumns = () => 2;

  getTextareaRowSize = () => 20;

  defaultValue = () => (
    '<h4>What Changed</h4>\n<p>\nPlaceHolder\n</p>\n<p>&nbsp;</p>\n<h4>Business Value</h4>\n<p>\nPlaceHolder\n</p>'
  );

  modalBodyRows = () => (
    <div>
      {this.getDateFieldRow('release_date', 'Release Date')}
      { this.getTextFieldRow('title', 'Title') }
      { this.getTextFieldRow('subtitle', 'Subtitle', false) }
      { this.yesNoField('is_visible', 'Visible') }
      { this.getTextareaRow('html_body', 'HTML body') }
    </div>
  );

  successAlertMsg = () => this.successMsg(this.formType());

  successMsg = (type) => (
    [
      `${type} ${this.isNew() ? 'created' : 'updated'} successfully`,
      'success',
    ]
  );
});
