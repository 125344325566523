// App info
import React from 'react';
import { AppJs } from '@westernmilling/react_component_library';
import { OktaAuth } from '@okta/okta-auth-js';

import OttoUpdateListContainer from 'containers/OttoUpdateListContainer';
import OttoUpdateViewContainer from 'containers/OttoUpdateViewContainer';

import './react_info.css';

const config = {
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  issuer: process.env.REACT_APP_OKTA_URL,
  redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URL,
};

export default class App extends AppJs {
  oktaAuth = new OktaAuth(config);

  defaultRoute = () => OttoUpdateListContainer;

  getImage = () => `/info${process.env.REACT_APP_URL_ENV}/images/otto_logo.gif`;

  underNav = () => (
    <div>
      <img src={this.getImage()} width="100%" alt="otto logo" />
      <br />
    </div>
  );

  routes = () => [
    { path: '/otto_updates/view/:uuid', component: OttoUpdateViewContainer },
    { path: '/otto_updates', component: OttoUpdateListContainer },
  ];
}
